.birth-data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /*align-items: center;*/
    height: 100px;
    width: 200px;
    font-size: 13px;
}
@media screen and (max-width: 1525px) {
    .birth-data {
        align-items: center;
    }
}

.btn {
    width: 100%;
    height: 35px;
    font-size: 14px;
    background: #CE17EF;
    letter-spacing: 0.2px;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
}

.btn:hover {
    cursor: pointer;
    background: #941ea8;
}