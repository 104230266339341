
.matrixs_container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;


  .matrixs-wrapper {
    display: flex;
    flex-direction: row;
  }

  .matrix-result {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid rgba(17, 66, 87, 0.27);
    padding-top: 45px;
    height: 650px;
  }

  .btn_compatibility-matrix {
    width: 250px;
    height: 45px;
    background-color: #CE17EF;

    &:hover {
      background-color: #941ea8;

    }
  }
}

@media screen and (max-width: 1120px) {

  .matrixs_container {
    .matrixs-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      @media screen and (max-width: 800px) {
        width: 460px;
      }
    }

    .matrix-result {
      display: flex;
      flex-direction: column;
      border-top: 1px solid rgba(17, 66, 87, 0.27);
      height: 1070px;
      padding-top: 50px;

      @media screen and (max-width: 800px) {
        height: 850px;
        .h3_life-map {
          top: -1px;
          font-size: 16px;
        }
      }

      .matrix-compatibility {
        min-height: 600px;

        @media screen and (max-width: 800px) {
          min-height: 350px;
        }
      }
    }
  }
}
