@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0px;
    color: #030303;
}

.container {
    margin: 0 auto;
    padding: 0 5px;
}

.main__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    min-height: 90vh;
    overflow-x: hidden;
    background: #ffffff;

}

@media screen and (max-width: 800px) {

}