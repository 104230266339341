
.appointment-compatibility {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 45px;
  width: 50%;
  padding-top: 20px;

  h3 {
    color: #3a3a3a;
  }


  .life-map_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .life-map_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: 270px;

      .life-map_item-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 2px solid #114257;
        border-radius: 50%;
        font-weight: bold;
      }

      .circle-bg {
        border: none;
        background-color: #526DFC;
        color: white;
      }

      .circle-bg-dark {
        border: none;
        background-color: #3366FF;
        color: white;
      }

      .life-map_item-title {
        font-size: 13px;
        color: #114257;
      }
    }
  }
}

@media screen and (max-width: 1120px) {

  .appointment-compatibility {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    width: 50%;
    padding-top: 20px;
    //border: 1px solid green;
    margin: 0 auto;
    margin-bottom: 55px;

    .life-map_wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .life-map_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 250px;

        .life-map_item-title {
          font-size: 12px;
        }
      }
    }
  }
}