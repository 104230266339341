.table_container {
  padding: 15px;
}

table {
  border-collapse: collapse;
  font-size: 15px;
  letter-spacing: 0px;
  border-radius: 10px;
  overflow: hidden;
}

tr {
  width: 100%;
}


th, td {
  border: 1px solid rgba(0, 0, 0, 0);
  height: 60px;
  text-align: center;
  letter-spacing: 0px;
}

.th_table {
  font-weight: normal;
  font-size: 17px;
}

td span {
  color: #331B07;
}

.th_header,
.th_footer {
  background: #F4F4F6;
  font-weight: normal;
  font-size: 17px;

  .td_number {
    background: #C6C8D4;
  }
}

.th_footer {
  font-weight: normal;
}


.th_small {
  width: 85px;
}

.th_large {
  width: 160px;
  text-align: start;
}

.th_xs {
  width: 32px;
  font-size: 40px;
}

.ch-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  padding-left: 10px;
  position: relative;

  .tooltip {

  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    padding-left: 10px;
    position: relative;
  }
}


.td_number {
  span {
    font-size: 17px;
    color: white;
  }
}

.ch-info {
  font-size: 11px;
}

.tr_seven {
  background: #F3ECF6;
  color: white;

  .td_number {
    background: #8847A3;
  }
}

.tr_six {
  background: #EDF0FF;

  .td_number {
    background: #526DFC;
  }
}

.tr_five {
  background: #ECFAFC;

  .td_number {
    background: #47CFDD;
  }
}

.tr_eight {
  background: #F7FCEF;

  .td_number {
    background: #B0DE61;
  }
}

.tr_three {
  background: #FFFAEC;

  .td_number {
    background: #FDCA46;
  }
}

.tr_two {
  background: #FCF5E5;

  .td_number {
    background: #DFA103;
  }
}

.tr_one {
  background: #FDECEC;

  .td_number {
    background: #EE4041;
  }
}

.tr_sum {
  background: #F4F4F6;

  .td_number {
    background: #C6C8D4;
  }
}

.td_sum {
  text-align: center;
}

@media screen and (max-width: 800px) {

  .th_table  {
    font-size: 15px;
  }

  .ch-name {
    font-size: 14px;

    .tooltip {
  
    }
  }

  .th_small {
    width: 50px;
    padding: 0 9px;
  }
  .th_large {
    width: 130px;
  }
}