.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;

  .form_h1, .form_h2 {
    font-family: 'Cormorant', serif;
    margin: 0;
    color: black;
    font-weight: normal;
  }
}

.label {
  margin-top: 15px;
}

.MuiInputBase-input {
  background: white;
}

.MuiTextField-root {
  background: white;
}

#form_btn {
  width: 100%;
  margin-top: 15px;
  background-color: #CE17EF;
  &:hover {
    background-color: #941ea8;
  }
}

@media screen and (max-width: 470px) {
  .form {
    .form_h1 {
      font-size: 28px;
    }
  }

}