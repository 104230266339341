.matrix_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.matrix-and-big-table-wrapper {

}

@media screen and (max-width: 1525px) {
  .matrix_container {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 0px;

  }
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 43%;

  .btn_compatibility-matrix {
    width: 250px;
    height: 45px;
    background-color: #CE17EF;
    border-radius: 50px;

    &:hover {
      background-color: #941ea8;
    }
  }


}


h3 {
  text-align: center;
  margin-bottom: 15px;
}
