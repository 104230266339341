h2 {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 20px;
}

.purposes_top,
.purposes_bottom {
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
}

.purpose {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.purpose_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 25px;
}
.purpose_general, .purpose_planetary {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle_purpose {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 2px solid #454545;
    font-weight: bold;
}

.purpose_h3 {
    font-size: 17px;
    text-align: center;
}



p {
    font-size: 12px;
    width: 230px;
    text-align: center;
    min-height: 70px;
}


.span {
    width: 10px;
    height: calc(50% - 10px);
    display: inline-block;
}

.rect {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 7px;
}

.left-brace-tl {
    border-right: 2px solid grey;
    border-bottom-right-radius: 10px;
    margin-right: -2px;
    margin-top: 10px;
}

.left-brace-tr {
    margin-bottom: 10px;
    border-left: 2px solid grey;
    border-top-left-radius: 10px;
}

.left-brace-bl {
    margin-top: -4px;
    border-right: 2px solid grey;
    border-top-right-radius: 10px;
    margin-right: -2px;
}

.left-brace-br {
    margin-bottom: -8px;
    border-left: 2px solid grey;
    border-bottom-left-radius: 10px;
}

.right-border {
    height: 100px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    width: 100px;
    height: 100px;
}

.circle_solo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;

}

.content_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: bold;
    color: #3A3A3A;
}

.own_power_code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.own_power_code-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}
.own_power_code-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 31%;
    font-size: 13px;
}
.map-of-year {
    margin-top: 25px;

}
.map-of-year_items {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
}


@media screen and (max-width: 800px) {
    p {
        width: 180px;
        margin-bottom: 20px;
    }

    .purpose {
        justify-content: space-between;

    }

    .content_row {
        font-size: 13px;
    }

    .word {
        padding-left: 10px;
        justify-content: space-around;
    }

    .purpose_bottom {
        justify-content: space-around;

    }
    .own_power_code-item {
        font-size: 10px;
    }

}
