* {
  box-sizing: border-box;
}

.matrix_wrapper {
  position: relative;
  height: 750px;
  width: 900px;
  margin-bottom: 100px;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 1;
}

@media screen and (max-width: 1525px) {
  .matrix_wrapper {
    margin-bottom: 20px;
  }
}

.circle {
  display: flex;
  position: absolute;
  z-index: 999;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #454545;
  font-weight: bold;
  background: #F5EFF3;
}

.circle_large {
  width: 70px;
  height: 70px;
  font-size: 40px;
}

.circle_large_inside {
  font-size: 40px;
  width: 62.5px;
  height: 62.5px;
  border: none;
}

.circle_medium {
  width: 47px;
  height: 47px;
  font-size: 25px;

}

.circle_medium_inside {
  width: 39.9px;
  height: 39.9px;
  border: none;
  color: white;
}

.circle_small {
  width: 36px;
  height: 36px;
  font-size: 20px;
}

.circle_small_inside {
  width: 28.3px;
  height: 28.3px;
  border: none;
  color: white;
}

.circle_xs {
  width: 20px;
  height: 20px;
  color: white;
  border: none;
  font-size: 14px;
}

#large-center {
  top: 340px;
  left: 407px;
  border: 2px solid #FDCA46;
  background-color: #FDCA46;

}

#large-center_inside {
  background: #FDCA46;
}

#large-1 {
  top: 26px;
  left: 407px;
  border: 2px solid #8847A3;
  background-color: #8847A3;
}

#large-1_inside {
  background: #8847A3;
  color: white;

}

#large-2 {
  top: 118px;
  left: 185px;
}

#large-3 {
  top: 118px;
  left: 628px;
}

#large-4 {
  top: 340px;
  left: 93px;
  border: 2px solid #8847A3;
  background-color: #8847A3;
}

#large-4_inside {
  background: #8847A3;
  color: white;

}

#large-5 {
  top: 340px;
  left: 719px;
  border: 2px solid #D14A44;
  background-color: #D14A44;
}

#large-5_inside {
  background: #D14A44;
  color: white;

}

#large-6 {
  top: 561px;
  left: 185px;
}

#large-7 {
  top: 561px;
  left: 628px;
}

#large-8 {
  top: 653px;
  left: 407px;
  border: 2px solid #D14A44;
  background-color: #D14A44;
}

#large-8_inside {
  background: #D14A44;
  color: white;
}

hr {
  position: relative;
  border-top: 1px solid #808080;
}

#hr_top-left {
  -webkit-transform: rotate(157deg);
  -moz-transform: rotate(157deg);
  -ms-transform: rotate(157deg);
  -o-transform: rotate(157deg);
  width: 250px;
  top: 80px;
  left: 183px;
}

#hr_top-right {
  -webkit-transform: rotate(24deg);
  -moz-transform: rotate(24deg);
  -ms-transform: rotate(24deg);
  -o-transform: rotate(24deg);
  width: 250px;
  top: 80px;
  left: 448px;
}

#hr_top-left-2 {
  -webkit-transform: rotate(112deg);
  -moz-transform: rotate(112deg);
  -ms-transform: rotate(112deg);
  -o-transform: rotate(112deg);
  width: 250px;
  top: 250px;
  left: 18px;
}

#hr_top-right-2 {
  -webkit-transform: rotate(68deg);
  -moz-transform: rotate(68deg);
  -ms-transform: rotate(68deg);
  -o-transform: rotate(68deg);
  width: 250px;
  top: 250px;
  left: 615px;
}

#hr_bot-left-2 {
  -webkit-transform: rotate(68deg);
  -moz-transform: rotate(68deg);
  -ms-transform: rotate(68deg);
  -o-transform: rotate(68deg);
  width: 250px;
  top: 495px;
  left: 23px;
}

#hr_bot-right-2 {
  -webkit-transform: rotate(112deg);
  -moz-transform: rotate(112deg);
  -ms-transform: rotate(112deg);
  -o-transform: rotate(112deg);
  width: 250px;
  top: 497px;
  left: 606px;
}

#hr_bot-left {
  -webkit-transform: rotate(23deg);
  -moz-transform: rotate(22deg);
  -ms-transform: rotate(22deg);
  -o-transform: rotate(22deg);
  width: 250px;
  top: 655px;
  left: 185px;
}

#hr_bot-right {
  -webkit-transform: rotate(157deg);
  -moz-transform: rotate(157deg);
  -ms-transform: rotate(157deg);
  -o-transform: rotate(157deg);
  width: 250px;
  top: 652px;
  left: 449px;
}

#hr_center-vertical {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  width: 383px;
  top: 357px;
  left: 249px;
}

#hr_center-horizontal {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  width: 388px;
  top: 373px;
  left: 247px;
}

#large_center-circle {
  position: absolute;
  top: 182px;
  left: 248px;
  width: 385px;
  height: 385px;
  background: none;
}

.square {
  position: absolute;
  top: 165px;
  left: 231px;
  border: 2px solid #454545;
  width: 420px;
  height: 420px;
}

#square-2 {
  transform: rotate(45deg);
}

#medium-1 {
  top: 98px;
  left: 418px;
  border: 2px solid #526DFC;
  background-color: #526DFC;
}

#medium-1_inside {
  background: #526DFC;
}

#medium-2 {
  top: 172px;
  left: 238px;
}

#medium-3 {
  top: 172px;
  left: 597px;
}

#medium-4 {
  top: 351px;
  left: 164px;
  border: 2px solid #526DFC;
  background-color: #526DFC;
}

#medium-4_inside {
  background: #526DFC;
}

#medium-5 {
  top: 351px;
  left: 671px;
}

#medium-6 {
  top: 531px;
  left: 238px;
}

#medium-7 {
  top: 531px;
  left: 597px;
}

#medium-8 {
  top: 605px;
  left: 418px;
}

#small-1 {
  top: 145px;
  left: 423px;
  border: 2px solid #47CFDD;
  background-color: #47CFDD;
}

#small-1_inside {
  background: #47CFDD;
}

#small-2 {
  top: 207px;
  left: 274px;
}

#small-3 {
  top: 207px;
  left: 572px;
}

#small-4 {
  top: 230px;
  left: 423px;
  border: 2px solid #73B55F;
  background-color: #73B55F;

}

#small-4_inside {
  background: #73B55F;
}


#small-5 {
  top: 357px;
  left: 212px;
  border: 2px solid #47CFDD;
  background-color: #47CFDD;
}

#small-5_inside {
  background: #47CFDD;
}

#small-6 {
  top: 357px;
  left: 297px;
  border: 2px solid #73B55F;
  background-color: #73B55F;
}

#small-6_inside {
  background: #73B55F;
}

#small-7 {
  top: 357px;
  left: 634px;
  border: 2px solid #DFA103;
  background-color: #DFA103;
}

#small-7_inside {
  background: #DFA103;
}

#small-8 {
  top: 506px;
  left: 273px;
}

#small-9 {
  top: 505px;
  left: 509px;
}

#small-10 {
  top: 506px;
  left: 573px;
}

#small-11 {
  top: 455px;
  left: 521px;
}

#small-12 {
  top: 442px;
  left: 571px;
}

#small-13 {
  top: 568px;
  left: 423px;
  border: 2px solid #DFA103;
  background-color: #DFA103;
}

#small-13_inside {
  background: #DFA103;
}

#small-14 {
  top: 357px;
  left: 500px;
  border: 2px solid #73B55F;
  background: #73B55F;
}
#small-14_inside {
  background: #73B55F;
}

#xs-1 {
  top: 7px;
  left: 430px;
  background: #8847A3;
}

#xs-2 {
  top: 113px;
  left: 178px;
  background: black;

}

#xs-3 {
  top: 113px;
  left: 684.9px;
  background: black;

}

#xs-4 {
  top: 364px;
  left: 74px;
  background: #8847A3;

}

#xs-5 {
  top: 364px;
  left: 611px;
  background: #DFA103;

}

#xs-6 {
  top: 364px;
  left: 788px;
  background: #D14A44;

}

#xs-7 {
  top: 432px;
  left: 560px;
  background: black;

}

#xs-8 {
  top: 445px;
  left: 509px;
  background: black;

}

#xs-9 {
  top: 495px;
  left: 497px;
  background: black;

}

#xs-10 {
  top: 546px;
  left: 431px;
  background: #DFA103;

}

#xs-11 {
  top: 617px;
  left: 179px;
  background: black;

}

#xs-12 {
  top: 722px;
  left: 432px;
  background: #D14A44;

}

#xs-13 {
  top: 617px;
  left: 684px;
  background: black;

}

#xs-14 {
  top: 409px;
  left: 431px;
  background: #F7DE74;
  color: black;

}

.small-arrow {
  height: 8px;
  position: absolute;
}

.years {
  position: absolute;
  font-weight: bold;
  font-size: 11px;
}

.years_left {
  left: -70px;
  top: -59px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.years_right {
  left: -70px;
  top: -2px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.arrow-right {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.arrow-left {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

#arrow-1 {
  top: 13px;
  left: 450px;
}

#arrow-2 {
  top: 119px;
  left: 703px;
}

#arrow-3 {
  top: 370px;
  left: 808px;
}

#arrow-4 {
  top: 624px;
  left: 703px;
}

#arrow-5 {
  top: 119px;
  left: 178px;
}

#arrow-6 {
  top: 370px;
  left: 74px;
}

#arrow-7 {
  top: 624px;
  left: 179px;
}

#arrow-8 {
  top: 730px;
  left: 432px;
}

#comfort-zone {
  position: absolute;
  top: 442px;
  left: 323px;
}

#hr_dashed {
  border-top: 1px dashed grey;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  width: 303px;
  top: 470px;
  left: 382px;
}

.arrow {
  position: absolute;
}

#arrow-top-red {
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  top: 235px;
  left: 558px;
}

#men-line {
  position: absolute;
  -webkit-transform: rotate(44.4deg);
  -moz-transform: rotate(44.4deg);
  -ms-transform: rotate(44.4deg);
  top: 280px;
  left: 240px;
  font-style: italic;
}

#women-line {
  position: absolute;
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  top: 280px;
  left: 408px;
  font-style: italic;
}

#sky-line {
  position: absolute;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  top: 265px;
  left: 347px;
  font-style: italic;
}

#earth-line {
  position: absolute;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  top: 360px;
  left: 458px;
  font-style: italic;
}

#arrow-bot-red {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  top: 491px;
  left: 302px;
}

#arrow-top-blue {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  top: 235px;
  left: 302px;
}

#arrow-bot-blue {
  -webkit-transform: rotate(310deg);
  -moz-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  -o-transform: rotate(300deg);
  top: 492px;
  left: 560px;
}

#heart {
  position: absolute;
  top: 460px;
  left: 460px;
  width: 40px;
}

#dollar {
  position: absolute;
  top: 392px;
  left: 538px;
  width: 40px;
}

#hr_rotate-1 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  width: 380px;
  top: 368px;
  left: 250px;
  border: 1px solid #526DFC;
}

#hr_rotate-2 {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  width: 380px;
  top: 366px;
  left: 250px;
  border: 1px solid red;
}


.dot {
  position: absolute;
  background-color: #454545;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

#dot-1 {
  top: 320px;
  left: 114px;
}

#dot-2 {
  top: 295px;
  left: 124px;
}

#dot-3 {
  top: 273px;
  left: 133px;
}

#dot-4 {
  top: 246px;
  left: 142px;
  width: 7px;
  height: 7px;
}

#dot-5 {
  top: 222px;
  left: 154px;
}

#dot-6 {
  top: 198px;
  left: 163px;
}

#dot-7 {
  top: 172px;
  left: 174px;
}

#dot-8 {
  top: 105px;
  left: 245px;
}

#dot-9 {
  top: 94px;
  left: 270px;
}

#dot-10 {
  top: 84px;
  left: 294px;
}

#dot-11 {
  top: 71px;
  left: 319px;
  width: 7px;
  height: 7px;
}

#dot-12 {
  top: 63px;
  left: 344px;
}

#dot-13 {
  top: 53px;
  left: 367px;
}

#dot-14 {
  top: 42px;
  left: 393px;
}

#dot-15 {
  top: 42px;
  left: 485px;
}

#dot-16 {
  top: 53px;
  left: 511px;
}

#dot-17 {
  top: 64px;
  left: 535px;
}

#dot-18 {
  top: 74px;
  left: 559px;
  width: 7px;
  height: 7px;
}

#dot-19 {
  top: 86px;
  left: 585px;
}

#dot-20 {
  top: 97px;
  left: 608px;
}

#dot-21 {
  top: 109px;
  left: 635px;
}

#dot-22 {
  top: 178px;
  left: 707px;
}

#dot-23 {
  top: 200px;
  left: 716px;
}

#dot-24 {
  top: 223px;
  left: 726px;
}

#dot-25 {
  top: 246px;
  left: 734px;
  width: 7px;
  height: 7px;
}

#dot-24 {
  top: 223px;
  left: 726px;
}
