.header {
    width: 100%;
    height: 8vh;
    background: white;
    box-shadow: 0px 2px 2px -2px rgba(128, 128, 128, 0);
    border-bottom: 1px solid rgba(128, 128, 128, 0.08);
}

.header_logo {
    height: 8vh;
}