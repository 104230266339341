* {
  box-sizing: border-box;
}

.matrix-compatibility {
  position: relative;
  min-height: 770px;
  width: 600px;
  margin-bottom: 100px;


  @media screen and (max-width: 1525px) {
    .matrix-compatibility {
      margin-bottom: 20px;
    }
  }

  h2 {
    width: 120px;
    position: absolute;
    left: calc(50% - (120px / 2));
  }

  .h3_life-map {
    width: 180px;
    position: absolute;
    left: calc(50% - (180px / 2));
    top: 20px;
    color: #3a3a3a;
  }

  .circle {
    display: flex;
    position: absolute;
    z-index: 999;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #454545;
    font-weight: bold;
  }

  .circle_large {
    width: 60px;
    height: 60px;
    font-size: 30px;
  }

  .circle_large_inside {
    font-size: 30px;
    width: 60px;
    height: 60px;
    border: none;
  }

  .circle_medium {
    width: 33px;
    height: 33px;
    font-size: 15px;

  }

  .circle_medium_inside {
    width: 33px;
    height: 33px;
    border: none;
    color: white;
  }

  .circle_small {
    width: 24px;
    height: 24px;
    font-size: 11px;
  }

  .circle_small_inside {
    width: 24px;
    height: 24px;
    border: none;
    color: white;
  }

  .circle_xs {
    width: 20px;
    height: 20px;
    color: white;
    border: none;
    font-size: 14px;
  }

  #large-center {
    top: 288px;
    left: 268px;
    border: 2px solid #FDCA46;
    background-color: #FDCA46;

  }

  #large-center_inside {
    background: #FDCA46;
  }

  #large-1 {
    top: 61px;
    left: 268px;
    border: 2px solid #8847A3;
    background-color: #8847A3;
  }

  #large-1_inside {
    background: #8847A3;
    color: white;

  }

  #large-2 {
    top: 124px;
    left: 105px;
  }

  #large-3 {
    top: 124px;
    left: 428px;
  }

  #large-4 {
    top: 285px;
    left: 40px;
    border: 2px solid #8847A3;
    background-color: #8847A3;
  }

  #large-4_inside {
    background: #8847A3;
    color: white;

  }

  #large-5 {
    top: 285px;
    left: 496px;
    border: 2px solid #D14A44;
    background-color: #D14A44;
  }

  #large-5_inside {
    background: #D14A44;
    color: white;

  }

  #large-6 {
    top: 448px;
    left: 105px;
  }

  #large-7 {
    top: 448px;
    left: 428px;
  }

  #large-8 {
    top: 513px;
    left: 268px;
    border: 2px solid #D14A44;
    background-color: #D14A44;
  }

  #large-8_inside {
    background: #D14A44;
    color: white;
  }

  hr {
    position: relative;
    border-top: 1px solid #808080;
  }

  #hr_top-left {
    -webkit-transform: rotate(157deg);
    -moz-transform: rotate(157deg);
    -ms-transform: rotate(157deg);
    -o-transform: rotate(157deg);
    width: 250px;
    top: 80px;
    left: 183px;
  }

  #hr_top-right {
    -webkit-transform: rotate(24deg);
    -moz-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    -o-transform: rotate(24deg);
    width: 250px;
    top: 80px;
    left: 448px;
  }

  #hr_top-left-2 {
    -webkit-transform: rotate(112deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 250px;
    top: 250px;
    left: 18px;
  }

  #hr_top-right-2 {
    -webkit-transform: rotate(68deg);
    -moz-transform: rotate(68deg);
    -ms-transform: rotate(68deg);
    -o-transform: rotate(68deg);
    width: 250px;
    top: 250px;
    left: 615px;
  }

  #hr_bot-left-2 {
    -webkit-transform: rotate(68deg);
    -moz-transform: rotate(68deg);
    -ms-transform: rotate(68deg);
    -o-transform: rotate(68deg);
    width: 250px;
    top: 495px;
    left: 23px;
  }

  #hr_bot-right-2 {
    -webkit-transform: rotate(112deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 250px;
    top: 497px;
    left: 606px;
  }

  #hr_bot-left {
    -webkit-transform: rotate(23deg);
    -moz-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    -o-transform: rotate(22deg);
    width: 250px;
    top: 655px;
    left: 185px;
  }

  #hr_bot-right {
    -webkit-transform: rotate(157deg);
    -moz-transform: rotate(157deg);
    -ms-transform: rotate(157deg);
    -o-transform: rotate(157deg);
    width: 250px;
    top: 652px;
    left: 449px;
  }

  #hr_center-vertical {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    width: 388px;
    top: 315px;
    left: 103px;
  }

  #hr_center-horizontal {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    width: 388px;
    top: 314px;
    left: 107px;
  }

  #large_center-circle {
    position: absolute;
    top: 182px;
    left: 248px;
    width: 385px;
    height: 385px;
    background: none;
  }

  .square {
    position: absolute;
    top: 165px;
    left: 145px;
    border: 2px solid #454545;
    width: 305px;
    height: 305px;
  }

  #square-2 {
    transform: rotate(45deg);
  }

  #medium-1 {
    top: 121px;
    left: 281px;
    border: 2px solid #526DFC;
    background-color: #526DFC;
  }

  #medium-1_inside {
    background: #526DFC;
  }

  #medium-2 {
    top: 169px;
    left: 152px;
  }

  #medium-3 {
    top: 171px;
    left: 410px;
  }

  #medium-4 {
    top: 299px;
    left: 100px;
    border: 2px solid #526DFC;
    background-color: #526DFC;
  }

  #medium-4_inside {
    background: #526DFC;
  }

  #medium-5 {
    top: 299px;
    left: 463px;
  }

  #medium-6 {
    top: 430px;
    left: 152px;
  }

  #medium-7 {
    top: 428px;
    left: 410px;
  }

  #medium-8 {
    top: 481px;
    left: 281px;
  }

  #small-1 {
    top: 154px;
    left: 286px;
    border: 2px solid #47CFDD;
    background-color: #47CFDD;
  }

  #small-1_inside {
    background: #47CFDD;
  }

  #small-2 {
    top: 193px;
    left: 177px;
  }

  #small-3 {
    top: 196px;
    left: 395px;
  }

  #small-4 {
    top: 198px;
    left: 286px;
    border: 2px solid #73B55F;
    background-color: #73B55F;

  }

  #small-4_inside {
    background: #73B55F;
  }


  #small-5 {
    top: 304px;
    left: 135px;
    border: 2px solid #47CFDD;
    background-color: #47CFDD;
  }

  #small-5_inside {
    background: #47CFDD;
  }

  #small-6 {
    top: 304px;
    left: 190px;
    border: 2px solid #73B55F;
    background-color: #73B55F;
  }

  #small-6_inside {
    background: #73B55F;
  }

  #small-7 {
    top: 304px;
    left: 437px;
    border: 2px solid #DFA103;
    background-color: #DFA103;
  }

  #small-7_inside {
    background: #DFA103;
  }

  #small-8 {
    top: 415px;
    left: 177px;
  }

  #small-9 {
    top: 410px;
    left: 331px;
  }

  #small-10 {
    top: 413px;
    left: 394px;
  }

  #small-11 {
    top: 379px;
    left: 361px;
  }

  #small-12 {
    top: 350px;
    left: 391px;
  }

  #small-13 {
    top: 458px;
    left: 286px;
    border: 2px solid #DFA103;
    background-color: #DFA103;
  }

  #small-13_inside {
    background: #DFA103;
  }

  #small-14 {
    top: 304px;
    left: 390px;
    border: 2px solid #73B55F;
    background: #73B55F;
  }

  #small-14_inside {
    background: #73B55F;
  }

  #xs-1 {
    top: 7px;
    left: 430px;
    background: #8847A3;
  }

  #xs-2 {
    top: 113px;
    left: 178px;
    background: black;

  }

  #xs-3 {
    top: 113px;
    left: 684.9px;
    background: black;

  }

  #xs-4 {
    top: 364px;
    left: 74px;
    background: #8847A3;

  }

  #xs-5 {
    top: 364px;
    left: 611px;
    background: #DFA103;

  }

  #xs-6 {
    top: 364px;
    left: 788px;
    background: #D14A44;

  }

  #xs-7 {
    top: 432px;
    left: 560px;
    background: black;

  }

  #xs-8 {
    top: 445px;
    left: 509px;
    background: black;

  }

  #xs-9 {
    top: 495px;
    left: 497px;
    background: black;

  }

  #xs-10 {
    top: 546px;
    left: 431px;
    background: #DFA103;

  }

  #xs-11 {
    top: 617px;
    left: 179px;
    background: black;

  }

  #xs-12 {
    top: 722px;
    left: 432px;
    background: #D14A44;

  }

  #xs-13 {
    top: 617px;
    left: 684px;
    background: black;

  }

  #xs-14 {
    top: 409px;
    left: 431px;
    background: #F7DE74;
    color: black;

  }

  .small-arrow {
    height: 8px;
    position: absolute;
  }

  .years {
    position: absolute;
    font-weight: bold;
    font-size: 11px;
  }

  .years_left {
    left: -70px;
    top: -59px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  .years_right {
    left: -70px;
    top: -2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .arrow-right {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .arrow-left {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  #arrow-1 {
    top: 13px;
    left: 450px;
  }

  #arrow-2 {
    top: 119px;
    left: 703px;
  }

  #arrow-3 {
    top: 370px;
    left: 808px;
  }

  #arrow-4 {
    top: 624px;
    left: 703px;
  }

  #arrow-5 {
    top: 119px;
    left: 178px;
  }

  #arrow-6 {
    top: 370px;
    left: 74px;
  }

  #arrow-7 {
    top: 624px;
    left: 179px;
  }

  #arrow-8 {
    top: 730px;
    left: 432px;
  }

  #comfort-zone {
    position: absolute;
    top: 442px;
    left: 323px;
  }

  #hr_dashed {
    border-top: 1px dashed grey;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 225px;
    top: 380px;
    left: 262px;
  }

  .arrow {
    position: absolute;
  }

  #arrow-top-red {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    top: 235px;
    left: 558px;
  }

  #men-line {
    position: absolute;
    -webkit-transform: rotate(44.4deg);
    -moz-transform: rotate(44.4deg);
    -ms-transform: rotate(44.4deg);
    top: 236px;
    left: 107px;
    font-style: italic;
    font-size: 10px;
  }

  #women-line {
    position: absolute;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    top: 240px;
    left: 256px;
    font-style: italic;
    font-size: 10px;
  }

  #sky-line {
    position: absolute;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    top: 218px;
    left: 205px;
    font-style: italic;
    font-size: 10px;

  }

  #earth-line {
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    top: 304px;
    left: 245px;
    font-style: italic;
    font-size: 10px;

  }

  #arrow-bot-red {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    top: 491px;
    left: 302px;
  }

  #arrow-top-blue {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    top: 235px;
    left: 302px;
  }

  #arrow-bot-blue {
    -webkit-transform: rotate(310deg);
    -moz-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    -o-transform: rotate(300deg);
    top: 492px;
    left: 560px;
  }

  #heart {
    position: absolute;
    top: 397px;
    left: 314px;
    width: 20px;
  }

  #dollar {
    position: absolute;
    top: 340px;
    left: 370px;
    width: 20px;
  }

  #hr_rotate-1 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    width: 410px;
    top: 310px;
    left: 91px;
    border: 1px solid #526DFC;
  }

  #hr_rotate-2 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 410px;
    top: 310px;
    left: 91px;
    border: 1px solid red;
  }


  .dot {
    position: absolute;
    background-color: #454545;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  #dot-1 {
    top: 320px;
    left: 114px;
  }

  #dot-2 {
    top: 295px;
    left: 124px;
  }

  #dot-3 {
    top: 273px;
    left: 133px;
  }

  #dot-4 {
    top: 246px;
    left: 142px;
    width: 7px;
    height: 7px;
  }

  #dot-5 {
    top: 222px;
    left: 154px;
  }

  #dot-6 {
    top: 198px;
    left: 163px;
  }

  #dot-7 {
    top: 172px;
    left: 174px;
  }

  #dot-8 {
    top: 105px;
    left: 245px;
  }

  #dot-9 {
    top: 94px;
    left: 270px;
  }

  #dot-10 {
    top: 84px;
    left: 294px;
  }

  #dot-11 {
    top: 71px;
    left: 319px;
    width: 7px;
    height: 7px;
  }

  #dot-12 {
    top: 63px;
    left: 344px;
  }

  #dot-13 {
    top: 53px;
    left: 367px;
  }

  #dot-14 {
    top: 42px;
    left: 393px;
  }

  #dot-15 {
    top: 42px;
    left: 485px;
  }

  #dot-16 {
    top: 53px;
    left: 511px;
  }

  #dot-17 {
    top: 64px;
    left: 535px;
  }

  #dot-18 {
    top: 74px;
    left: 559px;
    width: 7px;
    height: 7px;
  }

  #dot-19 {
    top: 86px;
    left: 585px;
  }

  #dot-20 {
    top: 97px;
    left: 608px;
  }

  #dot-21 {
    top: 109px;
    left: 635px;
  }

  #dot-22 {
    top: 178px;
    left: 707px;
  }

  #dot-23 {
    top: 200px;
    left: 716px;
  }

  #dot-24 {
    top: 223px;
    left: 726px;
  }

  #dot-25 {
    top: 246px;
    left: 734px;
    width: 7px;
    height: 7px;
  }

  #dot-24 {
    top: 223px;
    left: 726px;
  }


  .matrix-appointment {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;

    h3 {
      color: #3a3a3a;
    }

    .life-map_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;


      .life-map_items {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .life-map_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          width: 175px;
          margin-top: 5px;

          .life-map_item-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border: 2px solid #114257;
            border-radius: 50%;
            font-weight: bold;
            font-size: 12px;
          }

          .circle-bg {
            border: none;
            background-color: #526DFC;
            color: white;
          }

          .circle-bg-dark {
            border: none;
            background-color: #3366FF;
            color: white;
          }

          .life-map_item-title {
            font-size: 9px;
            color: #114257;

          }
        }
      }
    }


  }

}

