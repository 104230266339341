@media screen and (max-width: 800px) {

  * {
    box-sizing: border-box;
  }

  .matrix_wrapper {
    position: relative;
    height: 420px;
    width: 370px;
    margin-bottom: 100px;
    border: 1px solid rgba(255, 99, 71, 0);
    overflow-x: hidden;
  }

  @media screen and (max-width: 1525px) {
    .matrix_wrapper {
      margin-bottom: 20px;
    }
  }

  .circle {
    display: flex;
    position: absolute;
    z-index: 999;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #454545;
    font-weight: bold;
    background: #F5EFF3;
  }

  .circle_large {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  .circle_large_inside {
    font-size: 20px;
    width: 30px;
    height: 30px;
    border: none;
  }

  .circle_medium {
    width: 20px;
    height: 20px;
    font-size: 11px;
    border: 1px solid #454545;
  }

  .circle_medium_inside {
    width: 20px;
    height: 20px;
    border: none;
    color: white;
  }

  .circle_small {
    width: 16px;
    height: 16px;
    font-size: 9px;
    border: 1px solid black
  }

  .circle_small_inside {
    width: 16px;
    height: 16px;
    border: none;
    color: white;
  }

  .circle_xs {
    display: none;
    width: 20px;
    height: 20px;
    color: white;
    border: none;
    font-size: 14px;
  }

  #large-center {
    top: 165px;
    left: 172px;
    border: 2px solid #F7DE74;

  }

  #large-center_inside {
    background: #F7DE74;
  }

  #large-1 {
    top: 20px;
    left: 172px;
    border: 2px solid #993399;
  }

  #large-1_inside {
    background: #993399;
    color: white;

  }

  #large-2 {
    top: 62px;
    left: 72px;
  }

  #large-3 {
    top: 62px;
    left: 272px;
  }

  #large-4 {
    top: 165px;
    left: 27px;
    border: 2px solid #993399;
  }

  #large-4_inside {
    background: #993399;
    color: white;

  }

  #large-5 {
    top: 165px;
    left: 315px;
    border: 2px solid #D14A44;
  }

  #large-5_inside {
    background: #D14A44;
    color: white;

  }

  #large-6 {
    top: 268px;
    left: 68px;
  }

  #large-7 {
    top: 268px;
    left: 274px;
  }

  #large-8 {
    top: 309px;
    left: 172px;
    border: 2px solid #D14A44;
  }

  #large-8_inside {
    background: #D14A44;
    color: white;
  }

  hr {
    position: relative;
    border-top: 1px solid black;
  }

  #hr_top-left {
    -webkit-transform: rotate(157deg);
    -moz-transform: rotate(157deg);
    -ms-transform: rotate(157deg);
    -o-transform: rotate(157deg);
    width: 110px;
    top: 41px;
    left: 79px;
  }

  #hr_top-right {
    -webkit-transform: rotate(23deg);
    -moz-transform: rotate(23deg);
    -ms-transform: rotate(23deg);
    -o-transform: rotate(23deg);
    width: 110px;
    top: 40px;
    left: 186px;
  }

  #hr_top-left-2 {
    -webkit-transform: rotate(113deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 110px;
    top: 115px;
    left: -2px;
  }

  #hr_top-right-2 {
    -webkit-transform: rotate(67deg);
    -moz-transform: rotate(67deg);
    -ms-transform: rotate(67deg);
    -o-transform: rotate(67deg);
    width: 110px;
    top: 115px;
    left: 266px;
  }

  #hr_bot-left-2 {
    -webkit-transform: rotate(68deg);
    -moz-transform: rotate(68deg);
    -ms-transform: rotate(68deg);
    -o-transform: rotate(68deg);
    width: 110px;
    top: 228px;
    left: -6px;
  }

  #hr_bot-right-2 {
    -webkit-transform: rotate(112deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 110px;
    top: 228px;
    left: 266px;
  }

  #hr_bot-left {
    -webkit-transform: rotate(22deg);
    -moz-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    -o-transform: rotate(22deg);
    width: 110px;
    top: 305px;
    left: 77px;
  }

  #hr_bot-right {
    -webkit-transform: rotate(158deg);
    -moz-transform: rotate(158deg);
    -ms-transform: rotate(158deg);
    -o-transform: rotate(158deg);
    width: 110px;
    top: 302px;
    left: 186px;
  }

  #hr_center-vertical {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    width: 265px;
    top: 178px;
    left: 53px;
  }

  #hr_center-horizontal {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    width: 265px;
    top: 178px;
    left: 53px;
  }

  #large_center-circle {
    display: none;
    position: absolute;
    top: 182px;
    left: 248px;
    width: 385px;
    height: 385px;
    background: none;
  }

  .square {
    position: absolute;
    top: 83px;
    left: 88px;
    border: 2px solid #454545;
    width: 195px;
    height: 195px;
  }

  #square-2 {
    transform: rotate(45deg);
  }

  #medium-1 {
    top: 51px;
    left: 176px;
    border: 2px solid #526DFC;
  }

  #medium-1_inside {
    background: #526DFC;
  }

  #medium-2 {
    //display: none;
    top: 93px;
    left: 101px;
  }

  #medium-3 {
    //display: none;
    top: 96px;
    left: 253px;
  }

  #medium-4 {
    top: 170px;
    left: 58px;
    border: 2px solid #526DFC;
  }

  #medium-4_inside {
    background: #526DFC;
  }

  #medium-5 {
    top: 170px;
    left: 294px;
  }

  #medium-6 {
    //display: none;
    top: 248px;
    left: 101px;
  }

  #medium-7 {
    //display: none;
    top: 246px;
    left: 253px;
  }

  #medium-8 {
    top: 287px;
    left: 176px;
  }

  #small-1 {
    top: 73px;
    left: 178px;
    border: 2px solid #47CFDD;
  }

  #small-1_inside {
    background: #47CFDD;
  }

  #small-2 {
    top: 109px;
    left: 118px;
  }

  #small-3 {
    //display: none;
    top: 111px;
    left: 240px;
  }

  #small-4 {
    top: 120px;
    left: 178px;
    border: 2px solid #73B55F;
  }

  #small-4_inside {
    background: #73B55F;
  }


  #small-5 {
    top: 172px;
    left: 80px;
    border: 2px solid #47CFDD;
  }

  #small-5_inside {
    background: #47CFDD;
  }

  #small-6 {
    top: 172px;
    left: 125px;
    border: 2px solid #73B55F;
  }

  #small-6_inside {
    background: #73B55F;
  }

  #small-7 {
    top: 172px;
    left: 276px;
    border: 2px solid #DFA103;
  }

  #small-7_inside {
    background: #DFA103;
  }

  #small-8 {
    //display: none;
    top: 236px;
    left: 117px;
  }

  #small-9 {
    top: 246px;
    left: 203px;
  }

  #small-10 {
    //display: none;
    top: 233px;
    left: 241px;
  }

  #small-11 {
    top: 220px;
    left: 227px;
  }

  #small-12 {
    top: 198px;
    left: 251px;
  }

  #small-13 {
    top: 270px;
    left: 178px;
    border: 2px solid #DFA103;
  }

  #small-13_inside {
    background: #DFA103;
  }
  #small-14 {
    top: 172px;
    left: 215px;
    border: 2px solid #73B55F;
    background: #73B55F;
  }

  #xs-1 {
    top: 7px;
    left: 430px;
    background: #8847A3;
  }

  #xs-2 {
    top: 113px;
    left: 178px;
    background: black;

  }

  #xs-3 {
    top: 113px;
    left: 684.9px;
    background: black;

  }

  #xs-4 {
    top: 364px;
    left: 74px;
    background: #8847A3;

  }

  #xs-5 {
    top: 364px;
    left: 611px;
    background: #DFA103;

  }

  #xs-6 {
    top: 364px;
    left: 788px;
    background: #D14A44;

  }

  #xs-7 {
    top: 432px;
    left: 560px;
    background: black;

  }

  #xs-8 {
    top: 445px;
    left: 509px;
    background: black;

  }

  #xs-9 {
    top: 495px;
    left: 497px;
    background: black;

  }

  #xs-10 {
    top: 546px;
    left: 431px;
    background: #DFA103;

  }

  #xs-11 {
    top: 617px;
    left: 179px;
    background: black;

  }

  #xs-12 {
    top: 722px;
    left: 432px;
    background: #D14A44;

  }

  #xs-13 {
    top: 617px;
    left: 684px;
    background: black;

  }

  #xs-14 {
    top: 409px;
    left: 431px;
    background: #F7DE74;
    color: black;

  }

  .small-arrow {
    height: 8px;
    position: absolute;
  }

  .years {
    position: absolute;
    font-weight: bold;
    font-size: 8px;
  }

  .years_left {
    left: -70px;
    top: -69px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  .years_right {
    left: -70px;
    top: -2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .arrow-right {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .arrow-left {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  .small-arr-img {
    display: none;
  }

  #arrow-1 {
    top: 10px;
    left: 166px;
  }

  #arrow-2 {
    top: 65px;
    left: 302px;
  }

  #arrow-3 {
    top: 163px;
    left: 340px;
  }

  #arrow-4 {
    top: 288px;
    left: 305px;
  }

  #arrow-5 {
    top: 65px;
    left: 71px;
  }

  #arrow-6 {
    top: 163px;
    left: 33px;
  }

  #arrow-7 {
    top: 288px;
    left: 71px;
  }

  #arrow-8 {
    top: 342px;
    left: 207px;
  }

  #comfort-zone {
    display: none;
    position: absolute;
    top: 442px;
    left: 323px;
  }

  #hr_dashed {
    border-top: 1px dashed grey;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 130px;
    top: 222px;
    left: 168px;
  }

  .arrow {
    position: absolute;
    width: 13px;
  }

  #arrow-top-red {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    top: 87px;
    left: 268px;
  }


  #arrow-bot-red {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    top: 262px;
    left: 92px;
  }

  #arrow-top-blue {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    top: 86px;
    left: 94px;
  }

  #arrow-bot-blue {
    -webkit-transform: rotate(310deg);
    -moz-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    -o-transform: rotate(300deg);
    top: 261px;
    left: 269px;
  }

  #heart {
    position: absolute;
    top: 225px;
    left: 200px;
    width: 13px;
  }

  #men-line {
    position: absolute;
    -webkit-transform: rotate(44.4deg);
    -moz-transform: rotate(44.4deg);
    -ms-transform: rotate(44.4deg);
    top: 130px;
    left: 45px;
    font-style: italic;
    font-size: 6px;
  }

  #women-line {
    position: absolute;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    top: 130px;
    left: 150px;
    font-style: italic;
    font-size: 6px;
  }

  #sky-line {
    position: absolute;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    top: 70px;
    left: 123px;
    font-style: italic;
    font-size: 6px;
  }

  #earth-line {
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    top: 172px;
    left: 163px;
    font-style: italic;
    font-size: 6px;
  }

  #dollar {
    position: absolute;
    top: 193px;
    left: 228px;
    width: 13px;
  }

  #hr_rotate-1 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    width: 249px;
    top: 174px;
    left: 62px;
    border: 1px solid #526DFC;
  }

  #hr_rotate-2 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 249px;
    top: 174px;
    left: 62px;
    border: 1px solid red;

  }
}