
.matrix-selection-page_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 55px;
  align-items: center;
  height: 50vh;
  margin: auto;

  .matrix-selection-page_wrapper_h1 {
    font-size: 100px;
    font-weight: bold;
    font-family: 'Cormorant', serif;
    color: black;
    text-align: center;
  }

  .matrix-selection-page_wrapper_h3 {
    font-size: 25px;
    font-weight: normal;
    font-family: 'Cormorant', serif;
    color: black;
    margin-bottom: -50px;
  }

  .buttons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    width: 100%;

    .buttons_wrapper-btn {
      max-width: 280px;
      height: 60px;
      background-color: #CE17EF;
      border-radius: 50px;
      font-weight: bold;
      font-size: 15px;

      &:hover {
        background-color: #941ea8;
      }
    }
  }

  .social-links_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 400px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 800px) {

  .matrix-selection-page_wrapper {
    gap: 35px;

    .matrix-selection-page_wrapper_h1 {
      font-size: 60px;
      letter-spacing: 1px;
    }

    .matrix-selection-page_wrapper_h3 {
      font-size: 15px;
      margin-bottom: -30px;
    }

    .buttons_wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      .buttons_wrapper-btn {
        max-width: 250px;
        font-size: 14px;
        padding: 15px;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .matrix-selection-page_wrapper {
    gap: 35px;
    width: 100%;

    .matrix-selection-page_wrapper_h1 {
      font-size: 40px;
    }
  }
}